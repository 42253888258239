<template>
  <b-modal id="company-calendar-modal" title="Calendar Event" @ok="create">
    <b-form>
      <b-form-group label="Title">
        <b-form-input v-model="form.title"></b-form-input>
      </b-form-group>

      <b-form-group label="Date" v-model="form.date">
        <DatePicker v-model="form.date"></DatePicker>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import GCalendarService from "@/modules/company/services/calendar/g_calendar.service";

export default {
  name: "CompanyCalendarModal",
  components: { DatePicker },
  data() {
    return {
      form: {
        title: "",
        date: "",
      },
    };
  },
  methods: {
    async create() {
      try {
        const res = await GCalendarService.create(
          this.$gapi,
          this.form.date,
          this.form.title
        );

        console.log(res);

        await this.$bvModal.msgBoxOk("Success");
        this.$emit("event-created");
      } catch (e) {
        console.error(e);

        await this.$bvModal.msgBoxOk("Failed");
      }
    },
  },
};
</script>

<style scoped></style>

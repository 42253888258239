<template>
  <AppPageCard>
    <template #card-title> Calendar</template>
    <template #card-toolbar>
      <!--      <b-button class="mr-2" variant="light-primary" @click="addEvent"-->
      <!--        >Create</b-button-->
      <!--      >-->
      <!--      <b-button-->
      <!--        variant="light-primary"-->
      <!--        @click="calendarOptions.weekends = !calendarOptions.weekends"-->
      <!--        >Weekend</b-button-->
      <!--      >-->

      <b-button variant="light-primary" v-b-modal.company-calendar-modal
        >Add</b-button
      >
    </template>

    <FullCalendar class="" :options="calendarOptions"></FullCalendar>

    <CompanyCalendarModal @event-created="fetch"></CompanyCalendarModal>
  </AppPageCard>
</template>

<script>
import AppPageCard from "../../../core/components/shared/card/AppPageCard";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import dayjs from "dayjs";
import CompanyCalendarModal from "@/modules/company/components/calendar/CompanyCalendarModal";
import GCalendarService from "@/modules/company/services/calendar/g_calendar.service";

export default {
  name: "CompanyCalendarPage",
  components: { CompanyCalendarModal, AppPageCard, FullCalendar },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: "dayGridMonth",
        weekends: true,
        themeSystem: "bootstrap",
        events: [],
      },
    };
  },
  mounted() {
    this.fetch();
  },

  methods: {
    async fetch() {
      const events = await GCalendarService.list(this.$gapi);
      console.log(events);

      if (events.length > 0) {
        this.calendarOptions.events = [];

        for (let i = 0; i < events.length; i++) {
          let event = events[i];
          let when = dayjs(event.start.dateTime);

          if (event.start.dateTime) {
            console.log(
              `Date : ${event.summary} ${when} ${event.start.dateTime}`
            );
            this.calendarOptions.events.push({
              title: event.summary,
              date: when.format("YYYY-MM-DD"),
            });
          }
        }
      }
    },

    addEvent() {
      this.calendarOptions.events.push({
        title: "Test 123",
        start: "2021-12-01",
        end: "2021-12-17",
      });
    },
  },
};
</script>

<style scoped></style>

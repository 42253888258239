import dayjs from "dayjs";

const GCalendarService = {
  /**
   * List from GAPI
   *
   * @returns {Promise<unknown>}
   * @param gapiInstance
   */
  async list(gapiInstance) {
    const gapi = await gapiInstance.getGapiClient();
    console.log(gapi);

    return new Promise((resolve) => {
      gapi.auth.init(async () => {
        console.log("init");

        if (!gapiInstance.isAuthenticated()) await gapiInstance.login();
        // console.log(gapi.client.calendar.events.list);
        const response = await gapi.client.calendar.events.list({
          calendarId: "primary",
          showDeleted: false,
        });

        console.log(response);

        this.loop(response.result.items);
        resolve(response.result.items);
      });
    });
  },

  async create(gapiInstance, start, summary) {
    const gapi = await gapiInstance.getGapiClient();
    console.log(gapi);

    return new Promise((resolve) => {
      gapi.auth.init(async () => {
        console.log("init");

        if (!gapiInstance.isAuthenticated()) await gapiInstance.login();
        // console.log(gapi.client.calendar.events.list);
        const response = await gapi.client.calendar.events.insert({
          calendarId: "primary",
          start: {
            dateTime: start,
          },
          end: {
            dateTime: start,
          },
          summary: summary,
        });

        console.log(response);
        resolve(response);
      });
    });
  },

  /**
   * Loop events
   *
   * @param events
   */
  loop(events) {
    if (events.length > 0) {
      for (let i = 0; i < events.length; i++) {
        let event = events[i];
        let when = dayjs(event.start.dateTime);

        if (event.start.dateTime) {
          console.log(
            `Date : ${event.summary} ${when} ${event.start.dateTime}`
          );
        }
      }
    }
  },
};

export default GCalendarService;
